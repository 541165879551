<template>
  <div class="resetPassword xi">
    <nh-com></nh-com>
    <div class="banner">
      <div class="banner-wrap clearfix">
        <div class="banner-text">
          <h3>个人中心</h3>
          <p>当前位置： <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a></p>
        </div>
      </div>
    </div>
    <div class="personal-content">
      <div class="personal-box clearfix">
        <div class="personal-left">
          <hd-img></hd-img>
        </div>
        <div class="personal-right">
          <div class="personal-right-tit">
            <i class="iconfont">&#xe62c;</i><span>开具<em>处方</em></span>
          </div>
          <div class="iq-card-bodys">
            <el-descriptions class="margin-top" :column="3" border>
              <el-descriptions-item label="患者姓名">{{ patientList.name }}</el-descriptions-item>
              <el-descriptions-item label="年龄">{{ patientList.age }}岁</el-descriptions-item>
              <el-descriptions-item label="手机号">{{ patientList.phone }}</el-descriptions-item>
              <el-descriptions-item label="联系地址">{{ patientList.region != null ? patientList.region.fullName : "-" }}</el-descriptions-item>
            </el-descriptions>
          </div>
          <!-- 处方列表 -->
          <el-form label-width="100px" :model="RXForm" ref="RXForm" :rules="rules" style="width: 800px">
            <el-row type="flex" justify="space-between">
              <el-col :span="20">
                <el-form-item label="处方名称" prop="prescriptionName">
                  <el-input v-model="RXForm.prescriptionName" placeholder="请输入处方名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1">
                <p style="visibility: hidden">-</p>
              </el-col>
              <el-col :span="3">
                <el-button type="primary" @click="drawer = true">选择处方</el-button>
              </el-col>
            </el-row>
            <el-form-item label="">
              <el-table :data="RXForm.compositions" border style="width: 700px">
                <el-table-column prop="compositionName" align="center" label="成分">
                  <template slot-scope="scope">
                    <el-form-item label="" :prop="'compositions[' + scope.$index + '].compositionId'" :rules="rules.compositionId">
                      <el-select v-model="scope.row.compositionId" v-if="scope.row.edit" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                        <el-option v-for="item in EditDrugCompositio" :key="item.compositionId" :label="item.compositionName" :value="item.compositionId"> </el-option>
                      </el-select>
                      <span v-else v-html="scope.row.compositionName"></span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="dosage" align="center" label="用量">
                  <template slot-scope="scope">
                    <el-form-item label="" :prop="'compositions[' + scope.$index + '].dosage'" :rules="rules.dosage">
                      <el-input v-if="scope.row.edit" v-model="scope.row.dosage" size="small" placeholder="用量"> </el-input>
                      <span v-else>{{ scope.row.dosage }}</span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" align="center" label="炮制方法">
                  <template slot-scope="scope">
                    <el-select v-if="scope.row.edit" v-model="scope.row.remark" placeholder="炮制方法">
                      <el-option v-for="item in remarkOption" :key="item.value" :label="item.value" :value="item.value"> </el-option>
                    </el-select>
                    <span v-else>{{ scope.row.remark }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="150" align="center">
                  <template slot-scope="scope">
                    <el-button @click="confirmData(scope.row)" v-if="scope.row.edit" type="success" size="mini" plain>
                      <i class="el-icon-check" aria-hidden="true"></i>
                    </el-button>
                    <template v-else>
                      <el-button type="warning" size="mini" plain @click="editData(scope.row)">
                        <i class="el-icon-edit" aria-hidden="true"></i>
                      </el-button>
                    </template>
                    <el-button type="danger" size="mini" plain @click="handleDelete(scope.row, scope.$index)">
                      <i class="el-icon-delete" aria-hidden="true"></i>
                    </el-button>
                  </template>
                  <template slot="header">
                    <!-- 通过js添加行数据的按钮 -->
                    <el-button size="mini" @click="handleAdd()" type="primary" plain round>添加成分 </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-row>
              <el-col :span="12">
                <el-form-item label="剂量" prop="dosage">
                  <el-input v-model.number="RXForm.dosage" type="number" placeholder="单位（剂）"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用法用量" prop="number">
                  <el-input v-model.number="RXForm.number" type="number" placeholder="（次/日）"> </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="快递方式">
              <el-radio-group v-model="RXForm.isSelef">
                <el-radio :label="true">自取</el-radio>
                <el-radio :label="false">快递</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="其他备注">
              <el-input v-model="RXForm.remark" type="textarea"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="previewBtn('RXForm')">预览</el-button>
            </el-form-item>
          </el-form>
          <!-- 选择处方 -->
          <el-drawer title="中药方剂库" size="550px" :visible.sync="drawer" :direction="direction">
            <div class="drawer_body">
              <div class="iq-search-bar">
                <el-form action="#" class="searchbox" @submit.native.prevent>
                  <input type="text" class="text search-input" v-model="Keyword" placeholder="输入关键词搜索..." />
                </el-form>
              </div>
              <div class="category_box">
                <el-button :class="categoryId == item.categoryId ? 'active' : ''" plain size="small" v-for="(item, index) in herbalNavList" :key="index" @click="searchHerbal(item.categoryId)">{{ item.categoryName }}</el-button>
              </div>
              <div class="herbalList">
                <el-table v-loading="loading" height="450" :data="herbalData" style="width: 100%" @row-click="rowClick">
                  <el-table-column prop="herbalPrescriptionName" label="方剂名称"> </el-table-column>
                  <el-table-column label="操作" width="150px">
                    <template slot-scope="scope">
                      <el-button size="mini" type="success" @click="loadRXFrom(scope.row)" plain> 载入 </el-button>
                      <el-button size="mini" type="danger" plain>忽略</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="15" :current-page="PageIndex" :total="StaffTotal" layout="total, prev, pager, next, jumper"></el-pagination>
              </div>
              <div class="herbalDetail">
                <el-table v-loading="loadings" height="450" :data="herbalDetail.compositions" style="width: 100%">
                  <el-table-column prop="compositionName" label="成分" width="180">
                    <template slot-scope="scope">
                      <div v-html="scope.row.compositionName"></div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosage" label="用量" width="180"> </el-table-column>
                  <el-table-column prop="remark" label="炮制方法"> </el-table-column>
                </el-table>
              </div>
            </div>
          </el-drawer>
          <!-- 预览处方 -->
          <el-dialog title="预览" :visible.sync="preview">
            <el-descriptions class="margin-top" :column="3" border size="small">
              <el-descriptions-item label="患者姓名">{{ patientList.name }}</el-descriptions-item>
              <el-descriptions-item label="年龄">{{ patientList.age }}岁</el-descriptions-item>
              <el-descriptions-item label="手机号">{{ patientList.phone }}</el-descriptions-item>
              <el-descriptions-item label="身份证号">{{ patientList.idNo }}</el-descriptions-item>
              <el-descriptions-item label="联系地址">{{ patientList.region != null ? patientList.region.fullName : "暂未填写" }}{{ patientList.address != null ? patientList.address : "暂未填写" }}</el-descriptions-item>
            </el-descriptions>
            <p style="height: 30px; line-height: 30px; background-color: #ececec; margin: 10px 0; padding-left: 15px">
              {{ RXForm.prescriptionName }}
            </p>
            <el-table max-height="300" :data="RXForm.compositions" style="width: 100%">
              <el-table-column prop="compositionName" label="成分">
                <template slot-scope="scope">
                  <div v-html="scope.row.compositionName"></div>
                </template>
              </el-table-column>
              <el-table-column prop="dosage" align="center" label="用量"> </el-table-column>
              <el-table-column prop="remark" align="center" label="炮制方法"> </el-table-column>
            </el-table>
            <el-form label-width="120px" class="form-div">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="剂量" prop="dosage">
                    <el-input v-model.number="RXForm.dosage" type="number" placeholder="单位（剂）" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="用法用量" prop="number">
                    <el-input v-model.number="RXForm.number" type="number" placeholder="（次/日）" disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="快递方式">
                <el-radio-group v-model="RXForm.isSelef" disabled>
                  <el-radio :label="true">自取</el-radio>
                  <el-radio :label="false">快递</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="其他备注">
                <el-input v-model="RXForm.remark" type="textarea" disabled></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="preview = false">上一步</el-button>
              <el-button type="primary" @click="saveRX">确认提交</el-button>
            </span>
            <p style="height: 30px; line-height: 30px; background-color: #ececec; margin: 10px 0; padding-left: 15px" v-if="compatibilityRes.length > 0">以下配伍出自《中国药典》</p>
            <div class="tab-content">
              <div>
                <li v-for="(compare, index) in compatibilityRes" :key="index + 'i'">
                  <!-- <template v-if="compare.result"> -->
                  <div class="peitab-tit" @click="showCourseInfoList($event)">
                    <div v-if="compare.compatibilityType == 7">
                      <h4 v-for="(tip, index1) in compare.tips" :key="index1 + 'j'">
                        <span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                      </h4>
                    </div>
                    <div v-else>
                      <h4>
                        <span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                      </h4>
                      <div class="show-more">
                        <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                  <div v-if="compare.tips" class="peitab-mes">
                    <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + 'k'">
                      <div v-if="types.keys <= 3">
                        <p v-for="(val, index2) in types.value" :key="index2 + 'l'" v-html="namehtmlraw(val)"></p>
                      </div>
                      <div v-if="types.keys == 4">
                        <div v-for="(val, index2) in types.value.filter(i => i.title == '临床建议')" :key="index2 + 'n'">
                          <p v-html="namehtmlraw(val)"></p>
                        </div>
                      </div>
                      <div v-if="types.keys == 5">
                        <p v-for="(val, index2) in types.value" :key="index2 + 'o'" v-html="namehtmlraw(val)"></p>
                      </div>
                      <div v-if="types.keys == 7">
                        <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                      </div>
                      <div v-if="types.keys == 8">
                        <p v-for="(val, index2) in types.value.filter(i => i.title == '结果')" :key="index2 + 'p'" v-html="namehtmlraw(val)"></p>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="peitab-mes">
                      <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
                    </div> -->
                  <!-- </template> -->
                </li>
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
      <ft-com></ft-com>
      <rt-bar></rt-bar>
    </div>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
import { Region } from "../../components/region/Region";
import { Drug } from "../../components/Drug/Drug";
import { Herbal } from "../../components/Drug/Herbal";
import { tipGroup, namehtmlraw } from "../../utils/Compatibility";
// import rtBar from "../../components/right";
export default {
  name: "addRecord",
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + JSON.parse(localStorage.getItem("token")),
      };
    },
  },
  components: {
    ftCom,
    nhCom,
    hdImg,
    // rtBar,
  },
  data() {
    var region = new Region(this.TokenClient, this.Services.Authorization);
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var drug = new Drug(this.TokenClient, this.Services.Drug);
    var herbal = new Herbal(this.TokenClient, this.Services.Drug);
    return {
      patientDomain: patient,
      herbalDomain: herbal,
      Region: region,
      DrugDomain: drug,
      showCourseInfo: false,
      FileUploadUrl: this.Services.Statics + "/api/upload",
      patientId: this.$route.query.patientId, //列表页id
      patientDiagnoseId: this.$route.query.patientDiagnoseId, //列表页id
      ImageContent: [], //图片列表
      ImageContentList: [], //图片上传控件加载的数据
      DiagnoseContent: [], //图片列表
      patientList: [],
      remarkOption: [
        {
          value: "炒法",
        },
        {
          value: "炙法",
        },
        {
          value: "煅法",
        },
        {
          value: "蒸法",
        },
        {
          value: "煮法",
        },
        {
          value: "燀法",
        },
        {
          value: "复制法",
        },
        {
          value: "发酵法",
        },
        {
          value: "发芽法",
        },
        {
          value: "制霜法",
        },
        {
          value: "烘焙法",
        },
        {
          value: "煨法",
        },
        {
          value: "提净法",
        },
        {
          value: "水飞法",
        },
        {
          value: "干馏法",
        },
        {
          value: "特殊制法",
        },
      ],
      RXForm: {
        userId: this.$store.state.localData.userInfo.id,
        patientId: this.$route.query.patientId,
        patientDiagnoseId: this.$route.query.patientDiagnoseId,
        prescriptionName: "",
        isSelef: true,
        dosage: null,
        number: null,
        prescriptionType: 1,
        compositions: [
          {
            edit: true,
            compositionName: "",
            remark: "",
            dosage: "",
            category: null,
            categoryName: null,
            compositionAlias: null,
            compositionId: null,
          },
        ],
      },
      rules: {
        prescriptionName: [{ required: true, message: "请输入处方名称", trigger: "blur" }],
        dosage: [{ required: true, message: "请输入剂量", trigger: "blur" }],
        number: [{ required: true, message: "请输入用法用量", trigger: "blur" }],
        compositionId: [{ required: true, message: "请选择成分", trigger: "change" }],
        dosage: [
          {
            required: true,
            message: "请输入相应成分的用量",
            trigger: "change",
          },
        ],
      },
      EditDrugCompositio: [],
      drawer: false,
      direction: "rtl",
      Keyword: "",
      herbalNavList: [],
      herbalData: [],
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      StaffTotal: 0,
      loading: false,
      loadings: false,
      herbalDetail: {},
      categoryId: 0,
      searchId: "0",
      preview: false,
      compatibilityDialog: false,
      compatibilityRes: [],
    };
  },
  mounted() {
    this.getList();
    this.getHerbalCategorys();
  },
  methods: {
    tipGroup(list) {
      return tipGroup(list);
    },
    namehtmlraw(item) {
      return namehtmlraw(item);
    },
    rowClick(row) {
      var _this = this;
      _this.loadings = true;
      _this.DrugDomain.getHerbalPrescriptionDetail(
        row.hashKey,
        function (data) {
          _this.herbalDetail = data.data;
          _this.herbalDetail.compositions.forEach((item, i) => {
            let compositionName = item.compositionName;
            item.compositionName = compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, '<a target="blank" href="#/Drug/MaterialDetail?hashKey=$3">$1</a>');
          });
          _this.loadings = false;
        },
        function (error) {
          console.log("左侧分类请求失败:", error);
        }
      );
    },
    ConstituteChange(val, item) {
      // item.compositionName = this.$refs.selectLabel.selectedLabel
      this.EditDrugCompositio.map(res => {
        if (res.compositionId == val) {
          item.compositionName = res.compositionName;
        }
      });
      // console.log(this.$refs.selectLabel.selectedLabel);
      // console.log(item)
    },
    SelectComposition(val) {
      var _this = this;
      _this.DrugDomain.Compositions(
        val,
        "中成药",
        function (data) {
          _this.EditDrugCompositio = data.data.map(function (item) {
            // var label = `[${item.categoryName}]${item.compositionName}`;
            var label = `${item.compositionName}`;
            // if (item.compositionAlias) label += `${item.compositionAlias}`;
            return {
              compositionName: label,
              compositionId: item.compositionId,
            };
          });
        },
        function (err) {
          console.log(err);
        }
      );
    },
    //规格增删改---------开始
    handleAdd() {
      let row = {
        edit: true,
        compositionName: "",
        remark: "",
        dosage: "",
        category: null,
        categoryName: null,
        compositionAlias: null,
        compositionId: null,
      };
      this.RXForm.compositions.push(row);
    },
    editData(row) {
      this.$set(row, "edit", true);
    },
    confirmData(row) {
      row.edit = false;
      this.$notify({
        title: "Success",
        message: "编辑成功",
        type: "success",
        duration: 2000,
      });
    },
    handleDelete(row, index) {
      this.$notify({
        title: "Success",
        message: "删除成功",
        type: "success",
        duration: 2000,
      });
      this.RXForm.compositions.splice(index, 1);
    },
    getHerbalCategorys() {
      var _this = this;
      _this.DrugDomain.Categorys(
        function (data) {
          _this.herbalNavList = data.data;
        },
        function (error) {
          console.log("左侧分类请求失败:", error);
        }
      );
    },
    searchHerbal(item) {
      var _this = this;
      _this.categoryId = item;
      _this.loading = true;
      _this.DrugDomain.searchHerbals(
        _this.searchId,
        _this.categoryId,
        _this.PageIndex,
        _this.Keyword,
        function (data) {
          _this.herbalData = data.data.results;
          _this.PageIndex = data.data.pageIndex;
          _this.PageSize = data.data.pageSize;
          _this.PageTotal = data.data.pageCount;
          _this.StaffTotal = data.data.dataTotal;
          _this.loading = false;
        },
        function (error) {
          console.log("左侧分类请求失败:", error);
        }
      );
    },
    Search() {},
    SearchInputKeydown() {},
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex;
      this.searchHerbal(this.categoryId);
    },
    previewBtn(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          if (_this.RXForm.compositions.length < 2) {
            _this.preview = true;
            return;
          }
          _this.loading = true;
          var array = [];
          _this.RXForm.compositions.forEach(item => {
            if (item) {
              array.push({
                type: 4,
                key: item.key,
              });
            }
          });
          _this.herbalDomain.compatibility(
            array,
            function (data) {
              _this.loading = false;
              _this.compatibilityRes = data.data.compatibility;
              _this.preview = true;
            },
            function (error) {
              _this.loading = false;
              _this.preview = true;
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //列表项----点击展开收起
    showCourseInfoList(e) {
      const target = e.currentTarget;
      // 寻找父元素的兄弟元素进行显示与隐藏控制
      $(target).siblings().toggle();
      // 切换样式-图标
      $(target).find("i").toggleClass("el-icon-arrow-down").addClass("el-icon-arrow-up");
      if ($(target).siblings().is(":hidden")) {
        $(target).find(".show-text").html("展开");
      } else {
        $(target).find(".show-text").html("收起");
      }
    },
    loadRXFrom(row) {
      var _this = this;
      _this.loadings = true;
      _this.RXForm.compositions = [];
      _this.herbalDomain.Detail(
        row.hashKey,
        function (data) {
          _this.herbalDetail = data.data;
          _this.loadings = false;
          _this.RXForm.prescriptionName = row.herbalPrescriptionName;
          _this.herbalDetail.compositions.forEach((item, i) => {
            let compositionName = item.compositionName;
            item.compositionName = compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, '<a target="blank" href="#/Drug/MaterialDetail?hashKey=$3">$1</a>');
            _this.RXForm.compositions.push({
              compositionName: compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/, "$1"),
              remark: item.remark,
              dosage: item.dosage,
              category: item.category,
              categoryName: item.categoryName,
              compositionAlias: item.compositionAlias,
              compositionId: item.compositionId,
              key: compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/, "$3"),
            });
            _this.EditDrugCompositio = _this.RXForm.compositions.map(function (item) {
              return {
                compositionId: item.compositionId,
                compositionName: compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/, "$1"),
              };
            });
            _this.drawer = false;
          });
        },
        function (error) {
          console.log("左侧分类请求失败:", error);
        }
      );
    },
    getList() {
      var _this = this;
      _this.patientDomain.GetPatientDetail(
        _this.$route.query.patientId,
        function (data) {
          _this.patientList = data.data;
          _this.Region.getRegions(_this.patientList.id, function (data) {
            _this.patientList.region = data.data;
          });
        },
        function (error) {
          // _this.$message.error('修改失败！旧密码不正确，请重新输入。');
        }
      );
    },
    InitialRegion() {
      var _this = this;
      _this.Region.Regions(
        null,
        function (data) {
          _this.RegionData = data.data;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    LasyLoadRegion(node, resolve) {
      var _this = this;
      _this.Region.Regions(
        node.value,
        function (data) {
          for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
            data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 3;
          }
          resolve(data.data);
        },
        function (err) {
          resolve([]);
        }
      );
    },
    saveRX() {
      var _this = this;
      if (_this.compatibilityRes.length > 0) {
        _this
          .$confirm("该处方配伍异常, 是否继续提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            _this.saveData();
          })
          .catch(() => {
            _this.preview = false;
          });
      } else {
        _this.saveData();
      }
    },
    saveData() {
      var _this = this;
      _this.DrugDomain.SavePrescription(
        _this.RXForm,
        function (data) {
          _this.$message({
            type: "success",
            message: "您已经成功提交处方，请耐心等待审核！",
          });
          _this.$router.replace("/patienTmanagement/patientList");
        },
        function () {}
      );
    },
    goedit() {
      this.visible = true;
    },
    goadd() {
      this.$router.push("/patienTmanagement/addPatient");
    },
    addrecord() {
      this.$router.push("/patienTmanagement/addRecord");
    },
    onSubmit(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.diagnoseForm.PatientId = _this.$route.query.patientId;
          _this.patientDomain.AddDiagnose(
            _this.diagnoseForm,
            function (data) {
              _this.$alert("添加成功", "提示", {
                confirmButtonText: "确定并返回",
                type: "success",
                callback: action => {
                  _this.$router.go(-1);
                },
              });
            },
            function (error) {
              console.log(error);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";
.banner-text h3 {
  padding-top: 35px;
  font-size: 26px;
  font-weight: 400;
  color: #fff;
}
.banner {
  width: 100%;
  height: 160px;
  background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.patientheard {
  display: flex;
  align-items: center;
}

.patientBox {
  padding: 21px 42px;
  color: #808080;
}

.patient {
  padding: 21px 0 15px 0;
  border-bottom: 1px dashed #cccccc;
}

.pList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pListLeft {
  display: flex;
  align-items: center;
}

.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.banner-text {
  float: left;
}

.banner-text h4 {
  padding-top: 35px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 20px;
}

.banner-text p a {
  color: #ffffff;
  font-size: 14px;
  margin: 0 5px;
}

/**/
.personal-content {
  background: #f7f7f7;
  padding: 40px 0;
}

.personal-box {
  width: 1200px;
  margin: 0 auto;
}

.personal-left {
  float: left;
}

.personal-right {
  float: right;
  width: 900px;
  background: #ffffff;
  box-sizing: border-box;
}

.personal-right-tit {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px;
}

.personal-right-tit .iconfont {
  color: #04a290;
  font-size: 24px;
  margin-right: 5px;
}

.personal-right-tit span {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  color: #333333;
}

.personal-right-tit span em {
  font-style: normal;
  color: #04a290;
}

.personal-right > form {
  padding: 30px 15px;
  width: 460px;
}

.drawer_body {
  padding: 0 20px 20px;
}
::v-deep .el-table a {
  color: #b76c12;
}

.iq-search-bar .search-input {
  width: 90%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #e1ecfe;
  line-height: 1.8;
}

.category_box {
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;
}

.category_box .el-button {
  margin: 5px;
}

.category_box .el-button.active {
  color: #fff;
  background-color: #5c83f2;
  border-color: #5c83f2;
}

.form-div {
  margin-top: 10px;
}

.form-div /deep/ .el-form-item {
  margin-top: 5px;
  margin-bottom: 0;
}

.tab-content {
  margin-top: 30px;
}

.tab-content ul li {
}

.tab-content ul li:first-child {
}

.peitab-tit {
  position: relative;
  border-top: 1px solid #e5e5e5;
  padding: 10px 15px;
}

.peitab-tit h4 {
  display: inline-block;
  width: 830px;
  font-weight: normal;
  color: #333333;
  font-size: 16px;
}

.peitab-tit h4 span {
  color: #e94343;
}

.peitab-tit .show-more {
  display: inline-block;
  position: absolute;
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
}

.peitab-mes {
  display: none;
  width: 830px;
  padding: 5px 25px;
}

.peitab-mes p {
  line-height: 30px;
  color: #666666;
}

.peitab-mes .linkDetail {
  display: inline-block;
  color: #01c2ac;
  cursor: pointer;
}

.peitab-mes .linkDetail:hover {
  text-decoration: underline;
}
</style>
<style>
.personal-center {
  display: none;
}
</style>
