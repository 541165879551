import { render, staticRenderFns } from "./addprescript.vue?vue&type=template&id=64f143dc&scoped=true&"
import script from "./addprescript.vue?vue&type=script&lang=js&"
export * from "./addprescript.vue?vue&type=script&lang=js&"
import style0 from "./addprescript.vue?vue&type=style&index=0&id=64f143dc&prod&scoped=true&lang=css&"
import style1 from "./addprescript.vue?vue&type=style&index=1&id=64f143dc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f143dc",
  null
  
)

export default component.exports